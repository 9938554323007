<template>
  <div class="projectTree-wrap">
    <a-dropdown :trigger="['click']" class="dropdownStyle" :visible="visible">
      <div @click.stop="visible = true">
        <span class="tab_name">{{ multiple ? companyInfo.name : projectInfo.name }}</span>
        <DownOutlined />
      </div>
      <template #overlay>
        <div class="treeStyle" @click.stop>
          <a-tree
            defaultExpandAll
            :tree-data="treeData"
            v-model:selectedKeys="projectIds"
            :fieldNames="{
              children: 'children',
              title: 'name',
              key: 'projectBizId',
            }"
            :multiple="multiple"
            @select="handleSelectTree"
          >
          </a-tree>
        </div>
      </template>
    </a-dropdown>
  </div>
</template>
<script setup>
import { ref, defineEmits, onMounted, onUnmounted, defineProps } from "vue";
import { apiUserProjectTree } from "@/api/common.js";

const props = defineProps({
  initSelectAll:{
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(["select"]);
const visible = ref(false);
const companyInfo = ref({});
const treeData = ref([]);
const projectInfo = ref({});
const projectIds = ref(null);
const oldSelectIds = ref([]);
const oldSelectInfo = ref({});
let randomNum = 1;
const initProjectTree = async () => {
  let res = await apiUserProjectTree();
  const { children, name, bizNodeId } = res.result || {};
  if (Array.isArray(children)) {
    children.forEach((item) => {
      if (!item.projectBizId) {
        item.projectBizId = `${randomNum++}TreeKey`;
      }
      if (Array.isArray(item.children)) {
        item.children.forEach((inner) => {
          if (!inner.projectBizId) {
            inner.projectBizId = `${randomNum++}TreeKey`;
          }
        });
      }
    });
  }
  treeData.value = children || [];
  companyInfo.value = { name, id: bizNodeId };

  if(!props.initSelectAll){
    projectInfo.value = findFirstProject(res.result);
    projectIds.value = [projectInfo.value.projectBizId];
    oldSelectIds.value = projectIds.value;
    oldSelectInfo.value = projectInfo.value;
  }else{
    //多选-初次全选！
    projectInfo.value = findAllProject(res.result);
    projectIds.value = projectInfo.value.map(item=>item.projectBizId);
    oldSelectIds.value = projectIds.value;
    oldSelectInfo.value = projectInfo.value;
  }
  emits("select", projectIds.value);
};
const findFirstProject = (source) => {
  let findFlag = false;
  let result;
  function findLoop(source) {
    if (findFlag) return;
    if (source.projectBizId && !source.projectBizId.includes("TreeKey")) {
      findFlag = true;
      result = source;
    }
    if (source.children) {
      source.children.forEach((item) => {
        if (
          item.projectBizId &&
          !item.projectBizId.includes("TreeKey") &&
          !findFlag
        ) {
          result = item;
          findFlag = true;
        } else if (!findFlag) {
          findLoop(item);
        }
      });
    }
  }
  findLoop(source);
  return result;
};
const findAllProject = (source) => {
  let result = [];
  function findLoop(source) {
    if (source.children) {
      source.children.forEach((item) => {
        if (
          item.projectBizId &&
          !item.projectBizId.includes("TreeKey")
        ) {
          result.push(item);
        } 
        findLoop(item);
      });
    }
  }
  findLoop(source);
  return result;
};

const handleSelectTree = (...arg) => {
  let ids = arg[0][arg[0].length - 1];
  if (arg[0].length == 0 || ids.includes("TreeKey")) {
    projectIds.value = oldSelectIds.value;
    if (!props.multiple) {
      projectInfo.value = oldSelectInfo.value;
    }
    return;
  }
  projectIds.value = arg[0];
  oldSelectIds.value = arg[0];
  if (!props.multiple) {
    projectInfo.value = arg[1].node;
    oldSelectInfo.value = arg[1].node;
  }
  emits("select", projectIds.value);
};

const hideVisible = () => {
  visible.value = false;
};
onMounted(async () => {
  await initProjectTree();
  document.addEventListener("click", hideVisible);
});
onUnmounted(() => {
  document.removeEventListener("click", hideVisible);
});
</script>
<style lang="less" scoped>
.dropdownStyle {
  z-index: 10;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  span {
    margin-right: 5px;
  }
  .tab_name{
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 4px;
      left: 0;
      background: #0047ba;
      bottom: -10px;
    }
  }
}

.treeStyle {
  position: relative;
  padding: 18px 12px;
  box-shadow: 0 0 2px 2px #d8d8d8;
  background: #fff;
  max-height: calc(100vh - 500px);
  overflow-y: auto;
}
.projectTree-wrap{
    display: inline-block;
}
</style>
