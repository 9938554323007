<template>
  <div class="common-wrapper">
    <project-tree initSelectAll multiple @select="handleTreeSelect"></project-tree>
    <a-divider />
    <div class="header">
      <a-radio-group v-model:value="alarmType" button-style="solid">
        <a-badge :count="alarmTotal">
          <a-radio-button value="">全部</a-radio-button>
        </a-badge>
        <a-badge :count="alarmNum.conAlarm">
          <a-radio-button value="conAlarm">通讯告警</a-radio-button>
        </a-badge>
        <a-badge :count="alarmNum.devAlarm">
          <a-radio-button value="devAlarm">设备告警</a-radio-button>
        </a-badge>
        <a-badge :count="alarmNum.ruleAlarm">
          <a-radio-button value="ruleAlarm">规则告警</a-radio-button>
        </a-badge>
      </a-radio-group>
      <categorySearch :source="filterSource" class="searchBlock" @change="handleSearch" />
    </div>

    <basic-table ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotAlarmLevel="{ row }">
        <a-tag color="blue" v-if="row.alarmLevel == '00'">{{ row.alarmLevelName }}</a-tag>
        <a-tag color="green" v-else-if="row.alarmLevel == '01'">{{ row.alarmLevelName }}</a-tag>
        <a-tag color="orange"  v-else-if="row.alarmLevel == '02'">{{ row.alarmLevelName }}</a-tag>
        <a-tag color="red" v-else>{{ row.alarmLevelName }}</a-tag>
      </template>
    </basic-table>
  </div>

  <a-modal v-model:visible="modalVisible" title="确认告警" @ok="handleOk">
    <div>
      <a-textarea placeholder="请填写确认内容" v-model:value="confirmMessage"></a-textarea>
    </div>
  </a-modal>
  <a-modal v-model:visible="modalVisible2" title="确认告警" okText=" ">
    <a-descriptions>
      <a-descriptions-item :span="3" label="确认人">{{
        currentActionRow.confirmUser
        }}</a-descriptions-item>
      <a-descriptions-item :span="3" label="确认时间">
        {{ dayjs(currentActionRow.confirmTime).format("YYYY-MM-DD HH:mm:ss") }}
      </a-descriptions-item>
      <a-descriptions-item :span="3" label="备注">{{
        currentActionRow.confirmRemark
      }}</a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script setup>
import {
  ref,
  onMounted,
  onDeactivated,
  reactive,
  computed,
  watch,
  onActivated,
} from "vue";
import { apiAlarmManage } from "@/api/centerMonitor/alarmManage.js";
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import { apiDict } from "@/api/dict";
import _ from "lodash";
import { apiMonitorAlarm } from "@/api/centerMonitor/deviceAlarm.js";
import dayjs from "dayjs";
import projectTree from "@/components/business/projectTree/index.vue";

const tableRef = ref();
const alarmNum = reactive({
  conAlarm: 0,
  devAlarm: 0,
  ruleAlarm: 0,
  otherAlarm: 0,
});
const alarmTotal = computed(() => {
  return (
    alarmNum.conAlarm +
    alarmNum.devAlarm +
    alarmNum.ruleAlarm +
    alarmNum.otherAlarm
  );
});
const projectIds = ref(null);
const handleTreeSelect = (arrayIds) => {
  projectIds.value = arrayIds;
  initTableData();
  initCategory();
};

let tmr = ref(null);
onMounted(() => {
  initAlarmLevel();
});

onActivated(() => {
  tmr.value = setInterval(() => {
    initTableData();
    initCategory();
  }, 5000);
});
onDeactivated(() => {
  clearInterval(tmr.value);
  tmr.value = null;
});

const initAlarmLevel = () => {
  apiDict.selective("ALARM_LEVEL").then(({ result }) => {
    const _list = _.cloneDeep(filterSource.value);
    _list[1].options = result;
    filterSource.value = _list;
  });
};

const initCategory = () => {
  apiAlarmManage
    .getCategoryNum(projectIds.value.join(","))
    .then(({ result }) => {
      for (const key in alarmNum) {
        alarmNum[key] = 0;
      }
      for (const key in result) {
        let item = result[key];
        alarmNum[item.alarmType] = item.number;
      }
    });
};

const alarmType = ref("");
watch(
  () => alarmType.value,
  () => {
    tableRef.value.paginationProps.current = 1
    initTableData();
    initCategory();
  }
);

const searchForm = ref({});

const filterSource = ref([
  {
    label: "告警名称",
    type: "input",
    bindKey: "objName",
    placeholder: "请输入",
  },
  {
    label: "告警等级",
    type: "select",
    bindKey: "alarmLevel",
    placeholder: "请选择",
    options: [],
  },
  {
    label: "告警码",
    type: "input",
    bindKey: "alarmCode",
    placeholder: "请输入",
  },
  {
    label: "确认状态",
    type: "select",
    bindKey: "isConfirm",
    placeholder: "请选择",
    options: [
      { label: "已确认", value: true },
      { label: "未确认", value: false },
    ],
  },
]);

const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  scroll: { y: "calc(100vh - 400px)" },
  columns: [
    {
      title: "告警ID",
      type: "text",
      key: "eventId",
    },
    {
      title: "项目",
      type: "text",
      key: "projectName",
    },
    {
      title: "告警类型",
      type: "text",
      key: "alarmTypeName",
    },
    {
      title: "告警对象",
      type: "text",
      key: "objName",
    },
    {
      title: "告警码",
      type: "text",
      key: "alarmCode",
    },
    {
      title: "告警内容",
      type: "text",
      key: "alarmDesc",
    },
    {
      title: "告警等级",
      type: "customize",
      slotName: "slotAlarmLevel",
    },
    {
      title: "告警时间",
      type: "text",
      key: "eventTime",
      formatter(val) {
        return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ],
  action: {
    title: "操作",
    width: 100,
    fixed: "right",
    items: [
      {
        icon: "CheckCircleFilled",
        iconTip: "确认",
        type: "icon",
        visibleFn: (row) => !row.isConfirm,
        onClick: (row) => {
          handleConfirm(row);
        },
      },
      {
        icon: "EyeFilled",
        iconTip: "查看",
        type: "icon",
        visibleFn: (row) => row.isConfirm,
        onClick: (row) => {
          handleSee(row);
        },
      },
    ],
  },
});

const handleSearch = (form) => {
  searchForm.value = form;
  pageChange(1);
};
const initTableData = () => {
  apiAlarmManage
    .list({
      pageNo: tableRef.value.paginationProps.current,
      pageSize: tableRef.value.paginationProps.pageSize,
      projectBizIds: projectIds.value.join(","),
      alarmType: alarmType.value,
      ...searchForm.value,
    })
    .then(({ result }) => {
      tableRef.value.dataSourceRef = result.records || [];
      tableRef.value.paginationProps.total = result.total;
    });
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const confirmMessage = ref("");
const modalVisible = ref(false);
const handleOk = () => {
  if (currentActionRow.value.isConfirm) {
    modalVisible.value = false;
    return;
  }
  apiMonitorAlarm
    .actionConfirm({
      eventId: currentActionRow.value.eventId,
      remark: confirmMessage.value,
    })
    .then(() => {
      initTableData();
      modalVisible.value = false;
    });
};
const currentActionRow = ref(null);
const handleConfirm = (row) => {
  currentActionRow.value = row;
  modalVisible.value = true;
  confirmMessage.value = row.confirmRemark;
};

const modalVisible2 = ref(false);

const handleSee = (row) => {
  modalVisible2.value = true;
  currentActionRow.value = row;
};
</script>
<style lang="less" scoped>
.ant-radio-button-wrapper {
  background-color: #fafafa;
}

.ant-divider {
  margin: 20px 0;
}

.header {
  display: flex;
  gap: 20px;
}

.category-search {
  width: 350px;
}

.dropdownStyle {
  display: inline-block;
  z-index: 10;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  span {
    margin-right: 5px;
  }

  //     &::before {
  //         position: absolute;
  //         content: '';
  //         width: 100%;
  //         height: 4px;
  //         background: #EBEBEB;
  //         left: 0;
  //         bottom: -10px;
  //     }
}

.treeStyle {
  position: relative;
  padding: 18px 12px;
  box-shadow: 0 0 2px 2px #d8d8d8;
  background: #fff;
}

.common-wrapper {
  padding-top: 10px;
}

.ant-divider {
  margin-top: 10px;
}

::v-deep .ant-badge-count {
  transform: translate(0, -70%) !important;
  color: #1890FF;
  background-color: #E6F7FF;
}
</style>
