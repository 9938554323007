// 字典
import http from '../utils/http'

export const apiDict = {
    list(keywords) {
        return http.request({
            url: '/cemp-bms/dict/list',
            method: 'get',
            params: { keywords }
        })
    },
    detail(dictId) {
        return http.request({
            url: `/cemp-bms/dict/details/${dictId}`,
            method: 'get',
        })
    },
    editBase(data) {
        return http.request({
            url: '/cemp-bms/dict',
            method: 'put',
            data
        })
    },
    add(data) {
        return http.request({
            url: '/cemp-bms/dict/data',
            method: 'post',
            data
        })
    },
    edit(data) {
        return http.request({
            url: '/cemp-bms/dict/data',
            method: 'put',
            data
        })
    },
    delete(dictDataId) {
        return http.request({
            url: `/cemp-bms/dict/data/${dictDataId}`,
            method: 'delete'
        })
    },
    sort(data) {
        return http.request({
            url: '/cemp-bms/dict/data/sort',
            method: 'put',
            data
        })
    },
    // 根据code获取下拉列表
    selective(dictTypeCode) {
        return http.request({
            url: '/cemp-bms/dict/data/selective',
            method: 'get',
            params: { dictTypeCode }
        })
    }
}